import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";

export const frontmatter = {
  title: "Día 53",
  week: "Semana 8",
  month: "may",
  day: "05",
  monthNumber: "05",
  date: "2020-05-05",
  path: "/cronologia/semana-08#dia-05-may",
};
const Day53 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 2.260 nuevos positivos y 185 personas fallecidas.
          Permanecen hospitalizadas 119.609 personas, 720 más que el día
          anterior, y 2.143 reciben el alta. El número de casos ingresados en la
          UCI asciende a 11.031, lo que supone un aumento de 37 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,2 % y la tasa de recuperados del 49,3 %.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day53;
