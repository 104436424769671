import React from "react"
import { Flex, Text } from "@sqymagma/elements"
import { theme } from "../../../utils/themeGet"


export default ({ children }) => {
return (
    <Flex width={1} bg="brand05" my="m" p="1em" borderRadius="4px" >
        <Text
        as="p"
        textStyle="xl"
        css={`
            strong {
            color: ${theme("color.ui01")};
            }
        `}
        >
            {children}
        </Text>
    </Flex>
)}

