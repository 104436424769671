import React, { useState, useEffect } from "react";
import { Box, Flex, Text, Inline } from "@sqymagma/elements";
import { formatNumberDot } from "../../../utils/formatNumber";
import DataFactLoader from "../../Loaders/DataFactLoader";
import IconSystem from "../../IconSystem"
import usePascalCase from "../../../utils/usePascalCase"

export default ({
  fecha,
  ccaa,
  data,
  customText = null,
  customNumber = null,
}) => {
  const microCopy = {
    casosUci: "Personas en la UCI",
    casosFallecidos: "Personas fallecidas",
    casosHospitalizados: "Personas hospitalizadas",
    casosRecuperados: "Personas recuperadas",
    casosConfirmadosDiario: "Casos confirmados en el día",
    casosUciDiario: "Personas ingresadas en la UCI en el día",
    casosFallecidosDiario: "Personas fallecidas en el día",
    casosRecuperadosDiario: "Personas recuperadas en el día",
  };


  const [datafact, setDatafact] = useState(null);
  useEffect(() => {
    fetch(`https://covid19.secuoyas.io/api/v1/es/ccaa/?fecha=${fecha}`)
      .then((response) => response.json()) // parse JSON from request
      .then((resultData) => {
        const selectedCountry =
          resultData &&
          resultData.timeline[0].regiones.filter(
            (el) => el.codigoIsoLugar.toLowerCase() === ccaa.toLowerCase()
          );

        setDatafact(
          selectedCountry &&
            selectedCountry.map((el) => {
              return {
                ...el,
                ...el.data,
                datafact: el.data[data],
              };
            })[0]
        );
        
      })
      .catch((err) => console.log("Error getting data from API"));
  }, [fecha, ccaa, data]);

  const isoCode = usePascalCase(ccaa)
  
  return datafact && isoCode ? (
    <Box my="m" width={1}  maxWidth="400px">
      <Inline justifyContent="space-between">
        <Flex alignItems="center" width={[1/2, 1/3]}  pr={["s", 0]}>
          <Box width={1/2} mr="s" maxWidth={["3rem","4rem"]}  minWidth="2.5rem">
              <IconSystem name={`map${isoCode}`}/>
          </Box>
          <Flex flexDirection="column">
            <Box maxWidth="1.5rem" border="1px solid #cccccc" display="inline-grid" mb="xs">
                <IconSystem name={`flag${isoCode}`}/>
            </Box>
            <Text as="p" textStyle="l">
              {datafact && datafact.nombreLugar}
            </Text>
          </Flex>
        </Flex>
        <Inline width={[1/2, 2/3]} justifyContent="flex-end" alignItems={['flex-start', 'center']} flexDirection={["column", "row"]}>
          {customNumber ? (
            <Text fontWeight="800" fontSize="40px" color="brand02" mr="s">
              {formatNumberDot(customNumber)}
            </Text>
          ) : (
            datafact && (
              <Text fontWeight="800" fontSize="40px" color="brand02" lineHeight="1.2" mr={["xs", "s"]}>
                {datafact.datafact && formatNumberDot(datafact.datafact)}
              </Text>
            )
          )}
          {customText ? (
            <Text textStyle="s" color="brand05"  width={[1, "35%"]}>
              {customText}
            </Text>
          ) : microCopy[data] ? (
            <Text textStyle="s" color="brand05"  width={[1, "35%"]}>
              {microCopy[data]}
            </Text>
          ) : (
            "Error, revisa la prop data"
          )}
        </Inline>
      </Inline>
    </Box>
  ) : (
    <Box py="m" my="s" width={1}>
      <DataFactLoader />
    </Box>
  );
};
