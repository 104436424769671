import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 56",
  week: "Semana 8",
  day: "08",
  month: "may",
  monthNumber: "05",
  date: "2020-05-08",
  path: "/cronologia/semana-08#dia-08-may",
};
const Day56 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.262 nuevos positivos y 229 personas fallecidas.
          Permanecen hospitalizadas 121.776 personas, 762 más que el día
          anterior, y 2.637 reciben el alta. El número de casos ingresados en la
          UCI asciende a 11.222, lo que supone un aumento de 82 personas.{" "}
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,1 % y la tasa de recuperados del 50,4 %.{" "}
        </ModText>
        <ModGraph
          name="08-05-es-confirmados"
          alt="casos confirmados España con covid-19 del 2 al 8 mayo"
        />
        <ModGraph
          name="08-05-es-fallecidos"
          alt="casos fallecidos España con covid-19 del 2 al 8 mayo"
        />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day56;
