import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";
import ModDatafactCCAA from "../../../components/Chronology/Modules/ModDatafactCCAA";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";

export const frontmatter = {
  title: "Día 51",
  week: "Semana 8",
  month: "may",
  day: "03",
  monthNumber: "05",
  date: "2020-05-03",
  path: "/cronologia/semana-08#dia-03-may",
};
const Day51 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDatafactCCAA
          fecha={"2020-05-03"}
          ccaa="es-ct"
          data="casosRecuperados"
        />
        <ModGraph
          name="01-05-es-md-ct-fallecidos-semanal"
          alt="Fallecidos fin semana 6 de confiunamiento. Cataluña y Madrid suman más del 50% de las muertes"
        />
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.619 nuevos positivos y 281 personas fallecidas.
          Permanecen hospitalizadas 117.393 personas, 732 más que el día
          anterior, y 2.628 reciben el alta. El número de casos ingresados en la
          UCI asciende a 10.860, lo que supone un aumento de 84 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,2 % y la tasa de recuperados del 47,2 %.
        </ModText>
        <ModText>
          A partir de mañana, 4 de mayo,{" "}
          <strong>
            será obligatorio el uso de mascarilla en el transporte público
          </strong>
          colectivo. Por ello, el Gobierno llevará a cabo el reparto de El
          Gobierno repartirá, a partir de mañana, 14,5 mill. de mascarillas:
        </ModText>
        <ModDotList
          item1="6 mill. en nodos de transporte"
          item2="7 mill. en municipios a través de la FEMP"
          item3="1,5 mill. a entidades sociales"
        />
        <ModImage
          src="/images/svg/03_may_laboratorio.svg"
          alt="laboratorios PCR covid-19"
        />
        <ModText>
          Las comunidades autónomas disponen de 30 nuevos laboratorios de
          centros de investigación y universidades para realizar pruebas PCR.
          Con los 24 ya validados por el ISCII, son{" "}
          <strong>54 los laboratorios disponibles</strong>.
        </ModText>
        <ModText>
          El IGN, organismo oficial que genera la información geográfica de
          España, ha diseñado una
          <a
            href="https://www.ign.es/resources/viewer/calculadora1km.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            herramienta
          </a>
          que permite visualizar el área permitida para pasear o hacer deporte
          durante la primera etapa del Plan de desescalada.
        </ModText>
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/consumo/Documents/2020/030520%20GU%C3%8DA%20COMPRA%20MASCARILLAS.pdf"
            name="¿Qué debes tener en cuenta al comprar una mascarilla?"
          />
          <ReferenceRow
            link="https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/documentos/COVID19_Preguntas_y_respuestas_plan_nueva_normalidad.pdf"
            name="Plan para la transición: Preguntas frecuentes"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day51;
