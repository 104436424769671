import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDatafactCCAA from "../../../components/Chronology/Modules/ModDatafactCCAA";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";

export const frontmatter = {
  title: "Día 50",
  week: "Semana 8",
  month: "may",
  day: "02",
  monthNumber: "05",
  date: "2020-05-02",
  path: "/cronologia/semana-08#dia-02-may",
};
const Day50 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDatafactCCAA
          fecha={"2020-05-02"}
          ccaa="es-cm"
          data="casosFallecidos"
        />
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 2.579 nuevos positivos y 276 personas fallecidas.
          Permanecen hospitalizadas 117.949 personas, 556 más que el día
          anterior, y 2.570 reciben el alta. El número de casos ingresados en la
          UCI asciende a 10.909, lo que supone un aumento de 49 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,2 % y la tasa de recuperados del 47,7 %.
        </ModText>
        <ModGraph
          name="02-05-es-trayectorias"
          alt="evolucion de los casos confirmados"
        />
        <ModText>
          Un análisis de las trayectorias de casos confirmados paree indicar un
          repunte en ciertas comunidades autónomas. El incremento de los
          análisis seriológios impacta en el repunte que se ve en la gráfica.
          Sin embargo, es necesario seguir extremando la precaución.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day50;
