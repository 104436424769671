import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day56 from "./dia-08-may";
import Day55 from "./dia-07-may";
import Day54 from "./dia-06-may";
import Day53 from "./dia-05-may";
import Day52 from "./dia-04-may";
import Day51 from "./dia-03-may";
import Day50 from "./dia-02-may";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <Day56 {...props} />
      <Day55 {...props} />
      <Day54 {...props} />
      <Day53 {...props} />
      <Day52 {...props} />
      <Day51 {...props} />
      <Day50 {...props} />
    </LayoutWeek>
  );
};

export default Week;
