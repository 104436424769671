import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import { StrongGold } from "../../../components/Textstyles/Styles";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";

export const frontmatter = {
  title: "Día 52",
  week: "Semana 8",
  month: "may",
  day: "04",
  monthNumber: "05",
  date: "2020-05-04",
  path: "/cronologia/semana-08#dia-04-may",
};
const Day52 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 1.179 nuevos positivos y 164 personas fallecidas.
          Permanecen hospitalizadas 118.889 personas, 394 más que el día
          anterior, y 2.441 reciben el alta. El número de casos ingresados en la
          UCI asciende a 10.994, lo que supone un aumento de 20 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,2 % y la tasa de recuperados del 48,9 %.
        </ModText>
        <ModDailyHighlight>
          <StrongGold>La Rioja</StrongGold> no ha sufrido{" "}
          <strong>ningún fallecimiento</strong> por primera vez desde el 20 de
          Marzo
        </ModDailyHighlight>
        <ModGraph
          name="02-05-es-recuperados-semanal"
          alt="Fallecidos fin semana 6 de confinamiento. Cataluña y Madrid suman más del 50% de las muertes"
        />
        <ModText>
          El indice de recuperados semanales muestra una tendencia interesante.
          Mientras en las fases iniciales el 50% de los recuperados ocurrían en
          las zonas con mayor número de afectados, concentrados en Madrid y
          Cataluña, las últimas semanas muestran como el resto de España se
          cura.
        </ModText>
        <ModText>
          Asimismo, puede verse que se encadenan tres semanas seguidas con mayor
          número de recuperados.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day52;
