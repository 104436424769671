import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";

export const frontmatter = {
  title: "Día 54",
  week: "Semana 8",
  month: "may",
  day: "06",
  monthNumber: "05",
  date: "2020-05-06",
  path: "/cronologia/semana-08#dia-06-may",
};
const Day54 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.121 nuevos positivos y 244 personas fallecidas.
          Permanecen hospitalizadas 120.466 personas, 857 más que el día
          anterior, y 2.516 reciben el alta. El número de casos ingresados en la
          UCI asciende a 11.082, lo que supone un aumento de 51 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,2 % y la tasa de recuperados del 49,7 %.
        </ModText>
        <ModGraph
          name="06-05-cl-trayectorias"
          alt="trayectorias de confirmados por covid-19 Castilla y laón"
        />
        <ModText>
          Inicios paralelos, evoluciones que divergen, entre algunas comunidades
          autónomas.
        </ModText>
        <ModGraph
          name="06-05-cl-velocidadduplicacion"
          alt="velocidad de duplicación castilla y león"
        />
        <ModText>
          El desglose por provincias permite ver que la evolución no ha sido
          homogénea entre las distintas provinicas.
        </ModText>
        <ModGraph
          name="06-05-cl-confirmadosprovincias"
          alt="confinados"
        />
        <ModText>
          El Gobierno ha aprobado hoy un{" "}
          <strong>
            Real Decreto con medidas para apoyar e incentivar la cultura y el
            deporte
          </strong>{" "}
          tras las consecuencias ocasionadas por COVID-19. Entre las medidas
          implementadas, está el acceso extraordinario a la{" "}
          <strong>prestación por desempleo</strong> para trabajadores de la
          cultura y la inclusión de <strong>beneficios fiscales</strong> para
          impulsar el mecenazgo.
        </ModText>
        <ModImage
          src="/images/svg/06_05_farmacias.svg"
          alt="precio máximo de venta de antisepticos"
        />
        <ModText>
          También ha fijado el{" "}
          <strong>precio máximo de venta al público de los antisépticos</strong>
          de piel sana autorizados por la Agencia Española de Medicamentos y
          Productos Sanitarios (AEMPS).
        </ModText>
        <ModText>
          La <strong>Comunidad de Madrid renovará</strong> hasta diciembre el
          contrato de <strong>todos los profesionales sanitarios</strong>{" "}
          incorporados para hacer frente a la pandemia.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day54;
