import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDatafactCountry from "../../../components/Chronology/Modules/ModDatafactCountry";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";

export const frontmatter = {
  title: "Día 55",
  week: "Semana 8",
  day: "07",
  month: "may",
  monthNumber: "05",
  date: "2020-05-07",
  path: "/cronologia/semana-08#dia-07-may/",
};
const Day55 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDashedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
        <ModDatafactCountry
          fecha={frontmatter.date}
          country="uk"
          data="casosFallecidos"
        />
        <ModImage
          src="/images/svg/datafact/07-05-datafact-b.svg"
          alt="Proporción de casos recuperados por covid-19 España"
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.173 nuevos positivos y 213 personas fallecidas.
          Permanecen hospitalizadas 121.014 personas, 548 más que el día
          anterior, y 2.509 reciben el alta. El número de casos ingresados en la
          UCI asciende a 11.140, lo que supone un aumento de 58 personas.{" "}
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de letalidad
          es del 10,1 % y la tasa de recuperados del 50,0 %.{" "}
        </ModText>
        <ModGraph name="07-05-desfaseletalidad" alt="letalidad" />

        <ModText>
          Más de 46.700 personas han participado ya en el{" "}
          <strong>estudio nacional de epidemiología ENECovid19</strong> durante
          su primera semana, cuya fase de reclutamiento finaliza el próximo
          lunes 11 de mayo. Se trata de la primera de las tres oleadas de las
          que consta el estudio. En total, la previsión es alcanzar la cifra de
          entre 60.000 y 90.000 participantes.
        </ModText>
        <ModImage
          src="/images/svg/07_may_test-poblacion.svg"
          alt="estudio nacional epidemiología"
        />
        <ModText>
          Investigadores del CSIC han redactado un{" "}
          <InlineLink link="http://www.ciencia.gob.es/portal/site/MICINN/menuitem.edc7f2029a2be27d7010721001432ea0/?vgnextoid=768e0631e3ee1710VgnVCM1000001d04140aRCRD&vgnextchannel=4346846085f90210VgnVCM1000001034e20aRCRD&vgnextrefresh=1">
            informe sobre las vías de transmisión de COVID-19
          </InlineLink>
          en espacios destinados al baño y otras actividades acuáticas.
        </ModText>
        <ModText>
          La presidenta de la Comunidad de Madrid ha nombrado al{" "}
          <strong>
            doctor Antonio Zapatero como viceconsejero de Salud Pública y Plan
            COVID-19
          </strong>{" "}
          del Gobierno regional. El doctor ha sido uno de los referentes del
          Hospital temporal de la Comunidad de Madrid abierto en IFEMA una
          semana después de decretarse en España el estado de alarma por el
          coronavirus
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day55;
